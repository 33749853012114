function mouvementsController(DatatableLang, $state, $scope, WS, $window, $sce) {
    'ngInject';
    const vm = this;

    vm.mouvements = {};



    vm.getCommercialByEquipement = function (code, liste) {
        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {
            if (liste[i]['code'] == code) {
                return liste[i];
            }
        }
    }
    vm.getMouvements = function () {
        WS.getData('mouvements', vm.mvtdate)
            .then(function (response) {

                if (response.status == 200) {
                    vm.mouvements = response.data;
                    $scope.$apply();
                    // $('.table').DataTable({
                    //     "language": DatatableLang,
                    //     paging: false,
                    //     ordering: false
                    // });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.updatemouvement = function (id) {
        WS.get('mouvements/updateLigneMouvemnts/' + id)
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'Ligne Mouvemnts modifié avec Succès',
                        'success'
                    ).then(function () {

                        //    $window.location.reload();

                    })
                }
            })
            .then(null, function (error) {
                console.log(error);
                swal(
                    'ERROR !',
                    error.data.message,
                    'error'
                )

            });
    }

    vm.selectUsers = function () {
        WS.get('users/selectUsers')
            .then(function (response) {
                vm.users = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getGammes = function () {
        WS.get('gammes/select')
            .then(function (response) {
                if (response.status == 200) {
                    vm.gammes = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getSousFamilles = function () {
        WS.get('sousfamilles/select')
            .then(function (response) {
                if (response.status == 200) {
                    vm.sousfamilles = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getFamilles = function () {
        WS.get('familles/select')
            .then(function (response) {
                if (response.status == 200) {
                    vm.familles = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }




    vm.correctionMouvement = function (correction) {
        if (vm.files) {
            correction.file = vm.files[0];
        } else {
            correction.file = '';
        }

        WS.postwithimg('mouvements/correction', correction, "POST")
            .then(function (response) {
                swal(
                    'Succé',
                    'Correction ajoutée avec succées',
                    'success'
                ).then(function () {
                    $window.location.reload();
                    $scope.$apply();
                });
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.refreshMouvements = function () {
        WS.get('mouvements')
            .then(function (response) {
                vm.mouvements = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getByDate = function (mvtdate) {
        if (mvtdate.date_debut == undefined) {
            swal('Attention', 'Veuillez bien remplir le champ date debut', 'warning');
            return;
        } else if (mvtdate.date_fin == undefined) {
            swal('Attention', 'Veuillez bien remplir le champ date fin', 'warning');
            return;
        } else {
            if (mvtdate.user_code != undefined && mvtdate.user_code != '') {
                var url = 'mouvements/getByDate/' + mvtdate.date_debut + '/' + mvtdate.date_fin + '/' + mvtdate.user_code;
            } else {
                var url = 'mouvements/getByDate/' + mvtdate.date_debut + '/' + mvtdate.date_fin;
            }
            WS.getData(url, mvtdate)
                .then(function (response) {
                    //vm.mouvements={};
                    vm.mouvements = response.data;
                    $scope.$apply();
                })
                .then(null, function (error) {
                    console.log(error);
                });
        }
    };

    vm.getInventaire = function () {
        var url = 'mouvements/inventaire/' + vm.commercial;

        WS.get(url)
            .then(function (response) {
                console.log(response);
                if (response.status == 200) {
                    vm.inventaires = response.data;

                    $scope.$apply();
                    $('.table').DataTable({
                        "language": DatatableLang
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getLibelleBycode = function (code, liste, attribute) {
        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i][attribute] == code) {
                return liste[i];
            }

        }
    };


    vm.selectFournisseurs = function () {
        WS.get("fournisseurs/selectFournisseursWithCode")
            .then(function (response) {
                if (response.status == 200) {
                    vm.select_frs = response.data;
                    $scope.$apply();
                } else {
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.selectProduits = function () {
        WS.get("produits/selectProduitsWithCode")
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectproduits = response.data;
                    $scope.$apply();
                } else {
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    //   vm.selectProduits = function() {
    //     WS.get('produits/selectProduit')
    //         .then(function(response) {
    //             vm.produits = response.data;
    //             $scope.$apply();
    //         })
    //         .then(null, function(error) {
    //             console.log(error);
    //         });
    // };



    vm.getcommercial = function () {
        WS.get('users/query/grade="LIV"', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.commercials = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.exportExcel = function (mvtdate) {
        if (mvtdate.date_debut == undefined) {
            swal('Attention', 'Veuillez bien remplir le champ date debut', 'warning');
            return;
        } else if (mvtdate.date_fin == undefined) {
            swal('Attention', 'Veuillez bien remplir le champ date fin', 'warning');
            return;
        } else {
            if (mvtdate.user_code != undefined && mvtdate.user_code != '') {
                var url = 'mouvements/getByDate/' + mvtdate.date_debut + '/' + mvtdate.date_fin + '/' + mvtdate.user_code;
            } else {
                var url = 'mouvements/getByDate/' + mvtdate.date_debut + '/' + mvtdate.date_fin;
            }
            WS.get(url)
                .then(function (response) {

                    var filename = 'Mouvements-' + moment().format("DD-MM-YYYY_HH:mm:ss");
                    alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [stylecsv, response.data]);
                })
                .then(null, function (error) {
                    console.log(error);
                });
        }
    };



    vm.exportExcelTradisco = function (mvtdate) {
        console.log(mvtdate);
        if (mvtdate.date_debut == undefined) {
            swal('Attention', 'Veuillez bien remplir le champ date debut', 'warning');
            return;
        } else if (mvtdate.date_fin == undefined) {
            swal('Attention', 'Veuillez bien remplir le champ date fin', 'warning');
            return;
        } else {
            if (mvtdate.user_code != undefined && mvtdate.user_code != '') {
                var url = 'mouvements/exportTradisco/' + mvtdate.date_debut + '/' + mvtdate.date_fin + '/' + mvtdate.user_code;
            } else {
                var url = 'mouvements/exportTradisco/' + mvtdate.date_debut + '/' + mvtdate.date_fin;
            }
            WS.get(url)
                .then(function (response) {

                    var filename = 'Mouvements-' + moment().format("DD-MM-YYYY_HH:mm:ss");
                    alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [stylecsvTradisco, response.data]);
                })
                .then(null, function (error) {
                    console.log(error);
                });
        }
    };

    vm.exportExcelGroup = function (mvtdate) {
        console.log(mvtdate);
        if (mvtdate.date_debut == undefined) {
            swal('Attention', 'Veuillez bien remplir le champ date debut', 'warning');
            return;
        } else if (mvtdate.date_fin == undefined) {
            swal('Attention', 'Veuillez bien remplir le champ date fin', 'warning');
            return;
        } else {
            if (mvtdate.user_code != undefined && mvtdate.user_code != '') {
                var url = 'mouvements/exportGroup/' + mvtdate.date_debut + '/' + mvtdate.date_fin + '/' + mvtdate.user_code;
            } else {
                var url = 'mouvements/exportGroup/' + mvtdate.date_debut + '/' + mvtdate.date_fin;
            }
            WS.get(url)
                .then(function (response) {

                    var filename = 'Mouvements-' + moment().format("DD-MM-YYYY_HH:mm:ss");
                    alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [stylecsvGroup, response.data]);
                })
                .then(null, function (error) {
                    console.log(error);
                });
        }
    };

    var stylecsv = {
        sheetid: 'Mouvements - ' + moment().format("DD-MM-YYYY HH:mm:ss"),
        headers: true,
        caption: {
            title: 'Mouvements - ' + moment().format("DD-MM-YYYY HH:mm:ss"),
            style: 'font-size: 50px; color:blue;' // Sorry, styles do not works
        },
        //style:'background:#fff',
        column: {
            style: 'font-size:20px;background:#d1d1d1;'
        },
        columns: [
            { columnid: 'created_at', title: 'Date' },
            { columnid: 'numero', title: 'Numero Operation' },
            { columnid: 'code_commercial', title: 'Code Commercial' },
            { columnid: 'commercial', title: 'Commercial' },
            { columnid: 'type', title: 'Type' },
            { columnid: 'code', title: 'Code' },
            { columnid: 'code_erp_produit', title: 'code_erp produit' },
            { columnid: 'fournisseur', title: 'Fournisseur' },
            { columnid: 'soussociete', title: 'Sous Societe' },
            { columnid: 'produit', title: 'Produit' },
            { columnid: 'quantite', title: 'Quantite' },
            { columnid: 'etat', title: 'Etat' }
        ],
        row: {
            style: function (sheet, row, rowidx) {
                return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
        }
    };


    var stylecsvTradisco = {
        sheetid: 'Mouvements - ' + moment().format("DD-MM-YYYY HH:mm:ss"),
        headers: true,
        caption: {
            title: 'Mouvements - ' + moment().format("DD-MM-YYYY HH:mm:ss"),
            style: 'font-size: 50px; color:blue;' // Sorry, styles do not works
        },
        //style:'background:#fff',
        column: {
            style: 'font-size:20px;background:#d1d1d1;'
        },
        columns: [
            { columnid: 'date', title: 'Date' },
            { columnid: 'numero', title: 'Numero Operation' },
            { columnid: 'client_code', title: 'CODE CLIENT' },
            { columnid: 'client', title: 'NOM DU CLIENT' },
            { columnid: 'soussociete', title: 'Sous Societe' },
            { columnid: 'adresse_facturation', title: 'ADRESSE' },
            { columnid: 'region', title: 'REGION' },
            { columnid: 'matricule_fiscale', title: 'MATRICULE FISCALE' },
            { columnid: 'demande_facture', title: 'DEMANDE FACTURE' },
            { columnid: 'user_code', title: 'Code Commercial' },
            { columnid: 'commercial', title: 'Commercial' },
            { columnid: 'type', title: 'Type' },
            { columnid: 'code', title: 'Code' },
            { columnid: 'code_erp_produit', title: 'code_erp produit' },
            { columnid: 'produit', title: 'Produit' },
            { columnid: 'prix_ht', title: 'PRIX HT' },
            { columnid: 'remise', title: 'TAUX DE REMISE' },
            { columnid: 'remiseEspece', title: 'REMISE ESPECE' },
            { columnid: 'mode_paiement', title: 'mode paiement' },
            { columnid: 'quantite', title: 'Quantite' },
            { columnid: 'etat', title: 'Etat' }
        ],
        row: {
            style: function (sheet, row, rowidx) {
                return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
        }
    };

    var stylecsvGroup = {
        sheetid: 'Mouvements - ' + moment().format("DD-MM-YYYY HH:mm:ss"),
        headers: true,
        caption: {
            title: 'Mouvements - ' + moment().format("DD-MM-YYYY HH:mm:ss"),
            style: 'font-size: 50px; color:blue;' // Sorry, styles do not works
        },
        //style:'background:#fff',
        column: {
            style: 'font-size:20px;background:#d1d1d1;'
        },
        columns: [
            { columnid: 'soussociete', title: 'Sous Societe' },
            { columnid: 'code_commercial', title: 'Code Commercial' },
            { columnid: 'commercial', title: 'Commercial' },
            { columnid: 'magasinier', title: 'Magasinier' },
            { columnid: 'type', title: 'Type' },
            { columnid: 'etat', title: 'Etat' },
            { columnid: 'code', title: 'Code' },
            { columnid: 'code_erp_produit', title: 'code_erp produit' },
            { columnid: 'produit', title: 'Produit' },
            { columnid: 'prix_ttc', title: 'PRIX ttc' },
            { columnid: 'quantite', title: 'Quantite' },
            { columnid: 'total', title: 'Total' }

        ],
        row: {
            style: function (sheet, row, rowidx) {
                return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
        }
    };

    $scope.moveCSV = function (element) {
        var reader = new FileReader();
        reader.onload = function (event) {
            vm.csvfile.filetxt = event.target.result;
            $scope.$apply(function () {
                vm.files = element.files;
            });
        }
        reader.readAsDataURL(element.files[0]);
    };

    vm.importWavesoftMouvement = function () {
        vm.csvfile.filetxt = vm.files[0];
        WS.postwithimg('bl/importWavesoftMouvement', vm.csvfile, "POST")
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        response.data.message,
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        'Warning',
                        response.data.message,
                        'warning'
                    );
                }
            }, function (error) {
                console.log(error);
            });
    };

    vm.importExcelMouvement = function () {
        vm.csvfile.filetxt = vm.files[0];
        WS.postwithimg('mouvements/importExcelMouvement', vm.csvfile, "POST")
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        response.data.message,
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        'Warning',
                        response.data.message,
                        'warning'
                    );
                }
            }, function (error) {
                console.log(error);
            });
    };

    vm.trustHtml = function (html) {
        return $sce.trustAsHtml(html);
    };


    /**
    * double scroll table
    */
    $("document").ready(function () {
        $("#scroll1 div").width($(".table_scroll").width());
        $("#scroll1").on("scroll", function () {
            $("#scroll2").scrollLeft($(this).scrollLeft());
        });
        $("#scroll2").on("scroll", function () {
            $("#scroll1").scrollLeft($(this).scrollLeft());
        });
    });


    vm.getMouvementsByType = () => {
        WS.post('mouvements/getMouvementsByType', vm.mvtdate)
            .then(function (response) {

                vm.produits = response.data.produits;
                vm.dates = response.data.dates;
                vm.mouvements = response.data.mouvements;

                vm.group_prods = _.groupBy(vm.mouvements, element => element.code)




                console.log('vm.group_prods', vm.group_prods)
                var thead_tr = `<th class="text-center"> Ordre </th>
                                <th class="text-center">Code </th>
                                <th class="text-center"  style="border-right:2px solid #000"> Produit </th>
                                
                                <th class="text-center" style="background: aquamarine;"> Chargement </th>
                                <th class="text-center" style="background: aquamarine;"> Retour </th>
                                <th class="text-center" style="background: aquamarine;"> DLC </th>
                                <th class="text-center" style="border-right:2px solid #000;background: aquamarine;"> Casse </th>
                                `
                _.each(vm.dates, date => {
                    thead_tr += `<th class="text-center"> Chargement </th>
                    <th class="text-center"> Retour </th>
                    <th class="text-center"> DLC </th>
                    <th class="text-center" style="border-right:2px solid #000"> Casse </th>`
                })

                vm.thead_tr = vm.trustHtml(thead_tr);


                vm.total_prods = {};
                var tbodys = ''
                _.each(vm.produits, produit => {
                    // calculer le total de produit
                    var arrayProds = vm.group_prods[produit.code] ? vm.group_prods[produit.code] : [];
                    //init values total : 

                    vm.total_prods[produit.code] = {
                        [vm.mvtdate.type_ligne + '_chargement']: 0,
                        [vm.mvtdate.type_ligne + '_retour']: 0,
                        [vm.mvtdate.type_ligne + '_dlc']: 0,
                        [vm.mvtdate.type_ligne + '_casse']: 0
                    }

                    _.each(arrayProds, prod => {
                        vm.total_prods[produit.code][vm.mvtdate.type_ligne + '_chargement'] += prod[vm.mvtdate.type_ligne + '_chargement']
                        vm.total_prods[produit.code][vm.mvtdate.type_ligne + '_retour'] += prod[vm.mvtdate.type_ligne + '_retour']
                        vm.total_prods[produit.code][vm.mvtdate.type_ligne + '_dlc'] += prod[vm.mvtdate.type_ligne + '_dlc']
                        vm.total_prods[produit.code][vm.mvtdate.type_ligne + '_casse'] += prod[vm.mvtdate.type_ligne + '_casse']
                    })

                    tbodys += '<tr>'
                    tbodys += `
                            <td class="text-center">${produit.ordre}</td>
                            <td class="text-center">${produit.code}</td>
                            <td class="text-center" style="border-right:2px solid #000">${produit.libelle}</td>

                            <td class="text-center" style="background: aquamarine;"> ${vm.total_prods[produit.code][vm.mvtdate.type_ligne + '_chargement']} </td>
                            <td class="text-center" style="background: aquamarine;"> ${vm.total_prods[produit.code][vm.mvtdate.type_ligne + '_retour']} </td>
                            <td class="text-center" style="background: aquamarine;"> ${vm.total_prods[produit.code][vm.mvtdate.type_ligne + '_dlc']} </td>
                            <td class="text-center" style="border-right:2px solid #000;background: aquamarine;"> ${vm.total_prods[produit.code][vm.mvtdate.type_ligne + '_casse']} </td>
                        `;
                    _.each(vm.dates, date => {
                        tbodys += `
                            <td class="text-center" > 
                                ${vm.mouvements[date + '-' + produit.code] ? vm.mouvements[date + '-' + produit.code][vm.mvtdate.type_ligne + '_chargement'] : ''} 
                            </td>
                            <td class="text-center"> 
                                ${vm.mouvements[date + '-' + produit.code] ? vm.mouvements[date + '-' + produit.code][vm.mvtdate.type_ligne + '_retour'] : ''} 
                            </td>
                            <td class="text-center"> 
                                ${vm.mouvements[date + '-' + produit.code] ? vm.mouvements[date + '-' + produit.code][vm.mvtdate.type_ligne + '_dlc'] : ''} 
                             </td>
                            <td class="text-center" style="border-right:2px solid #000"> 
                                ${vm.mouvements[date + '-' + produit.code] ? vm.mouvements[date + '-' + produit.code][vm.mvtdate.type_ligne + '_casse'] : ''} 
                             </td >`
                    })

                    tbodys += '</tr>'

                })
                vm.tbodys = vm.trustHtml(tbodys);


                $scope.$apply();

                /**
                 * double scroll table
                 */
                $("document").ready(function () {
                    $("#scroll1 div").width($(".table_scroll").width());
                    $("#scroll1").on("scroll", function () {
                        $("#scroll2").scrollLeft($(this).scrollLeft());
                    });
                    $("#scroll2").on("scroll", function () {
                        $("#scroll1").scrollLeft($(this).scrollLeft());
                    });
                });
            }, function (error) {
                console.log(error);
            });
    }

    vm.exporttableHtml = function (tableId, title = null) {

        var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
            format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

        var table = $(tableId),
            ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
            href = uri + base64(format(template, ctx));

        //
        var download_link = document.createElement('a');
        download_link.href = href;
        if (!title) {
            title = 'Rapport-mvts-' + vm.mvtdate.type + '_' + vm.mvtdate.date_debut + '_' + vm.mvtdate.date_fin + '_';
            title += vm.mvtdate.gamme_code ? vm.mvtdate.gamme_code + '_' : '';
            title += vm.mvtdate.famille_code ? vm.mvtdate.famille_code + '_' : '';
            title += vm.mvtdate.sousfamille_code ? vm.mvtdate.sousfamille_code + '_' : '';
            title += vm.mvtdate.user_code ? vm.mvtdate.user_code + '_' : '';

        }

        download_link.download = title + ".xls";

        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);

    };

}
export default {
    name: 'mouvementsController',
    fn: mouvementsController
};