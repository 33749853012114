function inventaireController(DatatableLang, $state, $scope, WS, $window, AppSettings, $translate) {
    'ngInject';
    const vm = this;
    vm.searchbox = {};
    vm.auth_chargement = "1";
    vm.chargement_apply_filter = { chargement: vm.auth_chargement };
    vm.inventaire = {};
    vm.applyfilter = function () {
        if (vm.auth_chargement == "0") {
            vm.chargement_apply_filter = '';
        } else {
            vm.chargement_apply_filter = { chargement: vm.auth_chargement };
        }
    };


    vm.getParametrage = function () {
        WS.get('parametrages/getIndexForDashboard', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.parametrages = response.data;
                    //console.log(vm.parametrages);
                    vm.parametrages.validInventaireWithPassword = vm.parametrages.validInventaireWithPassword ?
                        vm.parametrages.validInventaireWithPassword.toString() : '0';

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getParametrage();
    vm.get = function (page = 1, per_page = 15) {
        var data = {
            per_page, page,
            // user_code: vm.searchbox.user_code,
            // depot_code: vm.searchbox.depot_code,
            date_debut: vm.searchbox.date_debut,
            date_fin: vm.searchbox.date_fin,
        }
        WS.getData('inventaire', data)
            .then(function (response) {
                if (response.status == 200) {
                    vm.inventaires = response.data.data;
                    vm.current_page = response.data.current_page;
                    vm.previous = vm.current_page - 1;
                    vm.next = vm.current_page + 1;
                    vm.from = response.data.form;
                    vm.last_page = response.data.last_page;
                    vm.next_page_url = response.data.next_page_url;
                    vm.per_page = Number(response.data.per_page);
                    vm.prev_page_url = response.data.prev_page_url;
                    vm.to = response.data.to;
                    vm.total = response.data.total;
                    vm.paginate = [];
                    for (var i = 1; i <= vm.last_page; i++) {
                        vm.paginate.push(i);
                    }

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.checkpaginate = function (current, page, lastpage) {
        if (page > current + 3 || page < current - 3) {
            return false;
        } else {
            return true;
        }
    }
    $(document).ready(function () {
        $(".sf_promo_gr_debut").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.searchbox.date_debut = date;
                $scope.$apply();
            }
        });

        $(".sf_promo_gr_fin").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.searchbox.date_fin = date;
                $scope.$apply();
            }
        });
    })

    $scope.moveCSV = function (element) {
        var reader = new FileReader();
        reader.onload = function (event) {
            vm.inventaire.file = event.target.result;
            $scope.$apply(function () {
                vm.files = element.files;
            });
        }
        reader.readAsDataURL(element.files[0]);
    };

    vm.storeEntete = function () {
        if (vm.files) {
            vm.inventaire.file = vm.files[0];
        } else {
            vm.inventaire.file = null;
        }

        if (vm.inventaire.soussociete_code) {
            WS.postwithimg('inventaire/storeEntete', vm.inventaire, "POST")
                .then(function (response) {
                    if (response.data.success) {
                        $scope.$apply();
                        swal(
                            $translate.instant('Succés'),
                            $translate.instant('Inventaire ajoutée succés'),
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        });
                    } else {
                        swal(
                            $translate.instant('Attention'),
                            $translate.instant(response.data.message),
                            'warning'
                        );
                    }
                }, function (error) {
                    console.log(error);
                    swal(
                        $translate.instant('Attention'),
                        $translate.instant('erreur d\'insertion!'),
                        'error'
                    );
                });
        } else {
            swal(
                $translate.instant('Attention'),
                $translate.instant('Vuillez bien choisir un distributeur'),
                'warning'
            );
        }

    };

    vm.getGammes = function () {
        WS.get('gammes')
            .then(function (response) {
                vm.gammes = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getFamilles = function () {
        WS.get('familles')
            .then(function (response) {
                vm.familles = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getSousfamilles = function () {
        WS.get('sousfamilles')
            .then(function (response) {
                vm.sousfamilles = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getproduits = function () {
        WS.getData('mouvements/names', { soussociete_code: vm.entete_achat.soussociete_code })
            .then(function (response) {
                vm.chargement.ch.entete_inventaire_code = $state.params.code;
                vm.produits = response.data.produits;
                vm.loading = false;
                $scope.$apply();
                vm.getCurrentInventaire(); //to get old quantites stored and ecart;

            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getCurrentInventaire = function () {
        WS.getScache('inventaire/getCurrentInventaire/' + $state.params.code)
            .then(function (response) {
                console.log(vm.produits, vm.chargement['ch']['produits'], response.data.length);
                if (response.data.length > 0) {

                    _.each(vm.chargement['ch']['produits'], (element, index) => {
                        response.data.forEach(ligne => {
                            if (ligne.produit_code == element.produit_code) {
                                vm.chargement['ch']['produits'][index]['quantite'] = ligne.quantite;
                                vm.chargement['ch']['produits'][index]['stk_before_inventaire'] = ligne.stk_before_inventaire;
                                vm.chargement['ch']['produits'][index]['stk_after_inventaire'] = ligne.stk_after_inventaire;
                                vm.chargement['ch']['produits'][index]['ecart'] = ligne.ecart;
                                //vm.chargement['ch']['produits'][index]['prix_achat_ht'] = ligne.prix_achat_ht;
                                vm.chargement['ch']['produits'][index]['prix_achat_ttc'] = ligne.prix_achat_ttc;
                            }
                        });
                    });
                }
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.selectSoussocietesOnly = function () {
        WS.get('soussocietes/selectSoussocietes').then(
            function (response) {
                vm.soussocietes = response.data;
                if (vm.soussocietes.length == 1) {
                    vm.inventaire.soussociete_code = vm.soussocietes[0].code;
                }

                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };


    vm.findEnteteByCode = function () {
        WS.getData('inventaire/findEnteteByCode', { code: $state.params.code })
            .then(function (response) {
                vm.entete_achat = response.data;
                $scope.$apply();
                vm.getproduits();

            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.convertirEnBox = function (chargement) {
        if (vm.c_box == true) {
            _.each(chargement.ch.produits, (value, key) => {
                if (value instanceof Object) {
                    vm.chargement['ch']['produits'][key]['quantite'] = Number(vm.chargement['ch']['produits'][key]['quantite']) / Number(value.colisage);

                }

            });
            // cocher colisage
        } else {
            _.each(chargement.ch.produits, (value, key) => {
                if (value instanceof Object) {
                    vm.chargement['ch']['produits'][key]['quantite'] = Number(vm.chargement['ch']['produits'][key]['quantite']) * Number(value.colisage);
                }

            });
            // decocher colisage
        }
        if (vm.c_box == false) { vm.c_box_title = "Convertir en Box"; } else { vm.c_box_title = "Convertir en piéce"; }
        vm.chargement['ch']['colisage'] = vm.c_box;
        vm.c_box = !vm.c_box;


    };

    vm.storeLigne_inventaire = function (chargement) {
        vm.btn_save = true;
        var data = {};
        data.produits = [];
        data.entete_inventaire_code = chargement.ch.entete_inventaire_code;
        data.colisage = chargement.ch.colisage;

        var negative_quantite = 0;
        _.each(chargement.ch.produits, (value, key) => {

            if (value.quantite != undefined && value.quantite != null) {
                if (value.quantite >= 0) {
                    data.produits.push(value);
                } else {
                    negative_quantite++;
                }
            }
        });

        if (negative_quantite == 0) {
            WS.post('inventaire/storeLigne', data)
                .then(function (response) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('chargement ajoutée avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    }).catch(function (error) {
                        $window.location.reload();
                    });
                    vm.btn_save = false;
                    $scope.$apply();
                })
                .then(null, function (error) {
                    console.log(error);
                });
        } else {
            swal(
                $translate.instant('Attention!'),
                $translate.instant('Vérifier les quantités négatives'),
                'warning'
            ).then(function () {
                vm.btn_save = false;
            }).catch(function (error) {
                vm.btn_save = false;
            });


        }
        vm.btn_save = false;

    };

    vm.clotureEnteteInventaire = function (entete_code) {
        swal({
            title: $translate.instant("Cloture"),
            text: $translate.instant("Valider l'inventaire de") + " : " + entete_code,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui'),
            cancelButtonText: $translate.instant('Non'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: true
        }).then(function () {

            WS.put('inventaire/clotureEnteteInventaire/' + entete_code, { cloture: 1, password: vm.current_password })
                .then(function (response) {
                    if (response.data.success == true) {
                        swal(
                            $translate.instant(`Succès`),
                            $translate.instant(`Inventaire validé avec succès`),
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        });
                    } else {
                        swal(
                            $translate.instant('Oups!'),
                            $translate.instant("Vous n'êtes pas autorisé"),
                            'warning'
                        );
                    }

                })
                .then(null, function (error) {
                    console.log(error);
                });
        }).catch(function () {
            console.log('cancelled!');
        });
    };

    vm.downloadLastStock = () => {
        if (vm.inventaire.soussociete_code) {
            WS.get('inventaire/downloadLastStock')
                .then(function (response) {
                    if (response.status == 200) {
                        var filename = "Model Inventaire-" + moment().format('YYYY-MM-DD hh:mm:ss');

                        var file = {
                            sheetid: filename,
                            headers: true,
                            // caption: {
                            //     title: filename,
                            //     style: 'font-size: 50px; color:blue;'
                            // },
                            column: {
                                style: 'font-size:20px;background:#d1d1d1;'
                            },
                            columns: [
                                { columnid: 'code', title: 'code' },
                                { columnid: 'quantite', title: 'quantite' },
                                { columnid: 'libelle', title: 'produit' },
                                { columnid: 'stock_theorique', title: 'Stock théorique' }

                            ],
                            row: {
                                style: function (sheet, row, rowidx) {
                                    return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                                }
                            }
                        };
                        //alasql('SELECT * INTO XLSX("' + filename + '.xlsx",?) FROM ?', [file, response.data]);
                        alasql('SELECT * INTO CSV("' + filename + '.csv",?) FROM ?', [file, response.data]);
                        $scope.$apply();
                    } else { }
                })
                .then(null, function (error) {
                    console.log(error);
                });
        }else{
            swal('Oups!!', "Veuillez bien saisir le distributeur!", "warning");
        }

    };

    vm.removeInventaire = (inventaire_code) => {
        swal({
            title: $translate.instant('Inventaire'),
            text: $translate.instant("Voulez-vous vraiment supprimer cet inventaire"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui'),
            cancelButtonText: $translate.instant('Non'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.post('inventaire/removeInventaire', { inventaire_code }).then(
                function () {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Inventaire supprimée avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                },
                function () {
                    swal(
                        $translate.instant('Attention!'),
                        $translate.instant('vous ne pouvez pas supprimer cet inventaire'),
                        'warning'
                    );
                }
            );
        });
    }
}
export default {
    name: 'inventaireController',
    fn: inventaireController
};